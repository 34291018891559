import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function WebsiteTerms() {
  return (
    <Layout>
      <SEO title="Website Terms of Use - Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Website Terms of Use</h1>
          <p className="last-updated">Last updated: February 9, 2021</p>
        </div>
      </section>
      <section className="terms-content sub-general-content scrollable-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
              <main>
                <div className="content">
                  <p>THESE WEBSITE TERMS OF USE (“TERMS”) CONTROL YOUR (“YOU” OR “YOUR”) ACCESS TO THE COMPUTE SOFTWARE, INC. (“WE,” “OUR” OR “US”) WEBSITE. BY ACCESSING THE WEBSITE AND ITS CONTENT YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE WITH THESE TERMS, YOU MAY NOT ACCESS OR USE OUR WEBSITE (THE “WEBSITE”).</p>
                  <p>THESE TERMS APPLY ONLY TO WEBSITE ACCESS. OUR PRODUCTS AND SERVICES ARE PROVIDED PURSUANT TO A SEPARATE AGREEMENT AVAILABLE AT TIME OF PURCHASE. YOU MUST ACCEPT THE SEPARATE AGREEMENT BEFORE YOU MAY USE ANY OF OUR PRODUCTS OR SERVICES.</p>
                  <h2 id="changes-to-terms">Changes to Terms</h2>
                  <p>We reserve the right, at our sole discretion, to modify or replace any part of these terms. It is your responsibility to check the Website and these terms periodically for changes. Your continued use of or access to the Website following the posting of any changes is acceptance of those changes. We may also, in the future, offer new services and/or features through the Website. Such new features and/or services shall be subject to these terms, as well as any separate agreements specifically governing them.</p>
                  <h2 id="electronic-communications-and-privacy">Electronic Communications and Privacy</h2>
                  <p>When you visit the Website, provide content, or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Any information about yourself that you provide while accessing the Website shall be governed by our Privacy Policy, which can be found at&nbsp;<a href="/privacy-policy">https://www.computesoftware.com/privacy-policy</a>. &nbsp;Further, the Website may automatically collect certain information from you as further described in our Privacy Policy.</p>
                  <h2 id="third-party-links">Third-Party Links</h2>
                  <p>The Website may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. &nbsp;We do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. &nbsp;If you access a third-party website from The Website, you do so at your own risk, and you understand that these Terms and our Privacy Policy do not apply to your use of such sites. &nbsp;You expressly relieve us from any and all liability arising from your use of any third-party website, service, or content. &nbsp;Additionally, your dealings with or participation in promotions of advertisers found on the Website, including payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. &nbsp;You agree that we shall not be responsible for any loss or damage of any sort relating to your dealings with such advertisers.</p>
                  <h2 id="intellectual-property">Intellectual Property</h2>
                  <p>All content (such as text, graphics, logos, button icons, images, audio clips, digital downloads, and data compilations) and all software and technology used, provided or implemented on or through the Website is the property of Compute Software, Inc., or its suppliers and protected by United States and international copyright and other laws. You may use such materials only in connection with your access of the Website. You may not copy, distribute, scrape, disassemble, reverse engineer or otherwise appropriate or create any derivative works from any of these materials. Any trademarks, service marks, graphics and logos used in connection with the Website are either our trademarks or those of third parties. You may not use any such marks for any purpose. We retain all rights in all materials, technology and marks on the Website, and do not grant any rights other than those explicitly granted by these terms.</p>
                  <h2 id="disclaimer-of-warranty">Disclaimer of Warranty</h2>
                  <p>THE &nbsp;CONTENT AND OTHER MATERIALS ON THE WEBSITE ARE SUPPLIED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND ALL SUCH WARRANTIES ARE DISCLAIMED INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, NON-INFRINGEMENT, OR FREEDOM FROM ERRORS. ONLY THOSE WARRANTIES MADE AVAILABLE UNDER SEPARATE AGREEMENTS RELATING TO THE PURCHASE OF SPECIFIC PRODUCTS OR SERVICES WILL APPLY. &nbsp;WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE WEBSITE, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
                  <h2 id="limitation-of-liability">Limitation of Liability</h2>
                  <p>IN NO EVENT WILL COMPUTE SOFTWARE, INC. OR ANY OF ITS AGENTS OR LICENSORS BE LIABLE TO YOU IN CONNECTION WITH YOUR ACCESS OF THE WEBSITE UNDER ANY LEGAL THEORY FOR DAMAGES, INCLUDING ANY DIRECT, SPECIAL, INCIDENTAL, PUNITIVE, INDIRECT OR CONSEQUENTIAL DAMAGES OR LOST PROFITS. &nbsp;THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. &nbsp;THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. &nbsp;FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. &nbsp;IN NO EVENT WILL COMPUTE SOFTWARE, INC. OR ANY OF ITS AGENTS OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING $50.00.</p>
                  <h2 id="indemnification">Indemnification</h2>
                  <p>You will indemnify and hold harmless Compute Software, Inc. and its agents and licensors, against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Website or your violation of these terms.</p>
                  <h2 id="product-ideas">Product Ideas</h2>
                  <p>If you submit comments or ideas about current or future offerings, including without limitation potential improvements to our products or services you understand that any such submissions are unsolicited and we may use them without restriction and you will not be entitled to any compensation.</p>
                  <h2 id="miscellaneous">Miscellaneous</h2>
                  <p>If any provision of these terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable and shall not affect the validity and enforceability of any remaining provisions. These terms constitute the entire agreement between you and Compute Software, Inc., regarding your access and use of the Website, and supersede all prior communications or terms, whether electronic, oral or written, between you and Compute Software, Inc. Failure by us to enforce these terms shall not be construed as a waiver. These terms and your use of the Website shall be governed by California law.</p>
                  <h2 id="contact">Contact</h2>
                  <p>If you have any questions, complaints, or claims with respect to the Website, you may contact us at&nbsp;<a href="mailto:contact@computesoftware.com">contact@computesoftware.com</a>, and we will try to resolve your concerns.</p>
                </div>
                <nav className="table-of-contents">
                  <h3>Table of Contents</h3>
                  <ol>
                    <li><a className="nav-link" href="#changes-to-terms">Changes to Terms</a></li>
                    <li><a className="nav-link" href="#electronic-communications-and-privacy">Electronic Communications and Privacy</a></li>
                    <li><a className="nav-link" href="#third-party-links">Third-Party Links</a></li>
                    <li><a className="nav-link" href="#intellectual-property">Intellectual Property</a></li>
                    <li><a className="nav-link" href="#disclaimer-of-warranty">Disclaimer of Warranty</a></li>
                    <li><a className="nav-link" href="#limitation-of-liability">Limitation of Liability</a></li>
                    <li><a className="nav-link" href="#indemnification">Indemnification</a></li>
                    <li><a className="nav-link" href="#product-ideas">Product Ideas</a></li>
                    <li><a className="nav-link" href="#miscellaneous">Miscellaneous</a></li>
                    <li><a className="nav-link" href="#contact">Contact</a></li>
                  </ol> 
                </nav>
              </main>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
